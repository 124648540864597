
import SearchWordLibrary from '/pages/search/components/SearchWordLibrary'

export default {
    name: 'SearchHead',
    components: {
        SearchWordLibrary
    },
    props: {
        lang: Object,
        middleEmit: Object,
        recently: Object,
    },
    data() {
        return {
            header: { // 头部
                keyword: '', // 搜索词
                placeholder: '',
                btnText: ''
            },
            cutting: { // 热搜词
                list: []
            },
            hasLibrary: false,
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 历史搜索点击
        handleSearch(obj) {
            this.header.keyword = obj.words
            this.onSearch()
        },
        // 搜索
        onSearch() {
            this.hasLibrary = false
            if(!this.header.keyword && this.header.placeholder != 'Please enter.' && this.cutting.list.length) {
                window.location.href = this.cutting.list[0].jumpUrl
                this.header.keyword = this.header.placeholder
                this.setRecentlySearch()
            } else {
                this.setRecentlySearch()
                let clear = setTimeout(() => {
                    this.jumpSearch()
                    clearTimeout(clear)
                }, 0)
            }
            this.popup.visible = false
            this.changeSearch()
        },
        // 搜索框输入
        inputSearch(e) {
            this.hasLibrary = true
        },
        // 搜索框获取焦点
        focusSearch() {
            this.header.keyword && this.inputSearch(this.header.keyword)
        },
        // 设置历史搜索
        setRecentlySearch() {
            if(!this.header.keyword) return
            this.recently.list.unshift({
                keyword: this.header.keyword
            })
            this.recently.list = this.recently.list.splice(0, 10)
            this.$storage.set('search/recently', this.recently.list)
        },
        // 清空搜索
        clearSearch() {
            this.hasLibrary = false
        },
        // 搜索变更
        changeSearch() {
            this.middleEmit.fn('changeSearch', this.header)
        },
        // 联想搜索
        wordTyping(obj) {
            this.header.keyword = obj.words
        },
        wordSearch(obj) {
            this.header.keyword = obj.words
            this.hasLibrary = false
            this.onSearch()
        },
        // 跳转
        jumpSearch() {
            this.$VueScrollTo.scrollTo('body', '', {
                duration: 0
            })
            this.setSearchLogAjax()
        },
        jumpBack() {
            if(window.history.length <= 1) {
                this.$router.push({
                    name: 'Home'
                })
            } else {
                this.$router.back()
            }
        },
        // 搜索日志
        setSearchLogAjax() {
            // this.$api.search.setSearchLog({
            //     searchWords: this.header.keyword
            // }).then(res => {
            // })
            this.$router.replace({
                name: 'Search',
                query: {
                    keyword: this.header.keyword
                }
            })
        },
        // 初始化
        initialSet() {
            this.header.keyword = this.$route.query.keyword || ''
            this.header.placeholder = this.header.keyword || `${this.$translate('Please enter')}.`
            this.header.btnText = this.$translate('Search')
        }
    },
}
