
import SearchHead from './components/SearchHead'
import SearchList from './components/SearchList'

export default {
    name: 'Search',
    layout: 'main-head',
    components: {
        SearchHead,
        SearchList
    },
    async asyncData({$api, cookies, ...context}) {
        const response = await $api.search.getSearchList({
            pageSize: 20,
            pageNum: 1,
            keyword: context.query.keyword,
            needFilter: true,
        }, false);
        const res = response?.result || {};
        const list = res.list || [];
        return {
            list,
            info: res
        }
    },
    props: {},
    data() {
        return {
            header: {
                keyword: ''
            },
            middleEmit: {},
            recently: { // 历史搜索
                visible: false,
                list: []
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getHistorySearch()
    },
    methods: {
        // 获取历史搜索
        getHistorySearch() {
            this.recently.list = this.$storage.get('search/recently') || []
        },
        // 搜索change
        changeSearch(obj) {
            const ref = this.$refs.SearchList;
            this.$set(this.header, 'keyword', obj.keyword)
            ref.changeSearch()
        },
        // SEO HEAD
        setHead() {
            const first = this.list[0]|| {};
            const pic = first.pics ? first.pics[0] : '';
            const head = this.$ctxApi.seoHead.initial({
                title: `${this.$route?.query?.keyword||''} - ${this.$translate('YFN Jewelry')}`,
                keywords: first.seoBanner,
                description: first.seoUrl,
                image: pic.url
            });
            return head
        },
        // 初始化
        initialSet() {
            this.header.keyword = this.$route.query.keyword || ''
            this.middleEmit.fn = (fn, options) => {
                this[fn](options)
            }
        }
    },
}
