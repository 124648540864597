
export default {
    name: 'SearchWordLibrary',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: Object,
        popup: {
            type: Object,
            default: () => {
                return {
                    visible: false
                }
            }
        }
    },
    data() {
        return {
            select: {
                words: '',
                visible: false,
                options: []
            },
            yfnCompo: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        options: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // watch
        setData() {
            this.changeWords()
        },
        changeWords(){
            if(!this.options.words) {
                this.setSearchEntry(false)
                return
            }
            this.$fnApi.debounce(() => {
                this.getSearchEntryAjax()
            })
        },
        // 获取搜索词条
        getSearchEntryAjax() {
            this.$api.search.getSearchEntry({
                words: this.options.words
            }).then(response => {
                const res = response.result || [];
                this.select.options = res
                this.select.visible = !!this.select.options.length
                this.setWordData()
                this.setSearchEntry(this.select.visible)
            })
        },
        setWordData() {
            this.select.options.forEach((p, index) => {
                const i = this.options.words.length
                const s = p.words.slice(0, i);
                const e = p.words.slice(i);
                const attr = `<span>${s}</span>${e}`;
                p.attr = attr
            })
        },
        // select
        handleSearch(obj) {
            this.select.words = obj.words
            this.yfnCompo.visible = false
            this.$emit('search', {
                ...obj,
                options: this.select.options
            })
        },
        handleTyping(obj) {
            this.$emit('typing', {
                ...obj,
                options: this.select.options
            })
        },
        // 联想搜索下拉菜单显示
        setSearchEntry(bool) {
            this.yfnCompo.visible = bool
            this.popup.visible = bool
        },
        closePopup() {
            this.popup.visible = false
        }
    },
}
