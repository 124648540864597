import { render, staticRenderFns } from "./SearchViewed.vue?vue&type=template&id=7060b3be&scoped=true&"
import script from "./SearchViewed.vue?vue&type=script&lang=js&"
export * from "./SearchViewed.vue?vue&type=script&lang=js&"
import style0 from "./SearchViewed.vue?vue&type=style&index=0&id=7060b3be&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7060b3be",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnProduct: require('/home/php1/m-nuxt/components/YfnProduct.vue').default})
