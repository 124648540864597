
export default {
    name: 'SearchRecently',
    components: {},
    props: {
        middleEmit: Object,
        recently: Object,
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleDelete() {
            this.$Dialog.confirm({
                confirmButtonText: this.$translate('Delete'),
                cancelButtonText: this.$translate('Cancel'),
                message: `${this.$translate('Are you sure you want to delete')}?`,
                getContainer: '.preSearch',
                className: 'preSearch-dialog',
                overlayClass: 'preSearch-dialog-overlay'
            }).then(() => {
                this.recently.visible = false
                this.recently.list = []
                this.$storage.set('search/recently', [])
            }).catch(() => {})
        },
        handleSearch(obj) {
            this.middleEmit.fn('handleSearch', {
                words: obj.keyword
            })
        }
    },
}
