
import SearchWordLibrary from '/pages/search/components/SearchWordLibrary'

export default {
    name: 'Pre-SearchHead',
    components: {
        SearchWordLibrary
    },
    props: {
        recently: Object,
        middleEmit: Object
    },
    data() {
        return {
            query: {},
            header: {               // 头部
                keyword: '',        // 搜索词
                placeholder: '',
                btnText: ''
            },
            cutting: {              // 热搜词
                visible: false,
                one: {},
                list: []
            },
            recWord: { // 推荐词
                val: '',
                ids: '',
                list: [],
                hasReferrerProduct: false, // 上一页是否商品详情
            },
            search: {               // 搜索
                keyword: '',        // 搜索词
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取底纹词
        getCuttingAjax() {
            this.$api.search.getSearchHot({
                type: 2
            }).then(response => {
                const res = response?.result || [];
                this.cutting.list = res || []
                this.cutting.visible = !!this.cutting.list.length
                this.cutting.one = this.cutting.list[0] || {}
                this.header.placeholder = this.cutting.one.words ? this.query.keyword || this.cutting.one.words : this.header.btnText
            })
        },
        // 重置底纹词
        setRecWord(obj) {
            if(obj.val) {
                this.header.placeholder = obj.val
            } else {
                this.getCuttingAjax()
            }
        },
        // 提交搜索
        submitSearch() {
            this.wordLibrary()
            this.setRecentlySearch()
            this.setSearchLogAjax()
        },
        // 历史搜索点击
        handleSearch(obj) {
            this.search.keyword = obj.words || obj.keyword
            this.submitSearch()
        },
        // 搜索按钮
        onSearch() {
            this.search.keyword = this.header.keyword || this.query.keyword || this.header.placeholder;
            // if(this.search.keyword == `${this.$translate('Please enter')}.`) {
            //     this.search.keyword = ''
            // }
            if(!this.search.keyword && this.cutting.one.jumpUrl ) {
                window.location.href = this.cutting.one.jumpUrl
            } else {
                this.submitSearch()
            }
        },
        // 设置历史搜索记录
        setRecentlySearch() {
            this.middleEmit.fn('setRecentlySearch', this.search.keyword)
        },
        // 清空搜索
        clearSearch() {
            this.header.placeholder = this.header.btnText
            this.wordLibrary()
        },
        // 联想搜索
        wordTyping(obj) {
            this.header.keyword = obj.words
        },
        wordSearch(obj) {
            this.search.keyword = obj.words
            this.submitSearch()
        },
        wordLibrary() {
            const ref = this.$refs.WordLibrary;
            ref.setSearchEntry(false)
        },
        // 跳转
        jumpSearch() {
            this.setSearchLogAjax()

        },
        jumpBack() {
            this.$router.back()
        },
        // 搜索日志
        setSearchLogAjax() {
            this.middleEmit.fn('setSearchLogAjax', this.search.keyword)
        },
        // 初始化
        initialSet() {
            // this.header.placeholder = `${this.$translate('Please enter')}.`
            this.header.btnText = this.$translate('Search')
            this.query = this.$route.query
        }
    },
}
