import { render, staticRenderFns } from "./setBasic.vue?vue&type=template&id=92e7e504&scoped=true&"
import script from "./setBasic.vue?vue&type=script&lang=js&"
export * from "./setBasic.vue?vue&type=script&lang=js&"
import style0 from "./setBasic.vue?vue&type=style&index=0&id=92e7e504&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92e7e504",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default})
