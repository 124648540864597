
export default {
    name: '',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object
    },
    data() {
        return {
            hot: {
                visible: false,
                list: [],
                rList: [],
            },
            recWord: { // 商品关键词
                visible: false
            },
            page: {
                pageNum: 0,
                pageSize: 8,
                totalPage: 1
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 获取热搜词
        getHotAjax() {
            this.$api.search.getSearchHot({
                type: 1
            }).then(response => {
                const res = response?.result || [];
                this.hot.list = res
                const length = this.hot.list.length;
                this.hot.visible = !!length
                this.page.totalPage = Math.ceil(length / this.page.pageSize)
                this.updatePage()
            })
        },
        updatePage() {
            this.page.pageNum += 1
            this.page.pageNum > this.page.totalPage && (this.page.pageNum = 1)
            let mergeList = JSON.parse(JSON.stringify(this.hot.list));
            this.recWord.proRecWord.forEach((p, i) => {
                mergeList.splice(i * 8, 0, ...p)
            })
            this.hot.rList = mergeList.slice((this.page.pageNum - 1) * 8, this.page.pageNum * 8);
        },
        // 商品关键词
        setRecWord(obj) {
            this.recWord = obj || {}
            const list = this.recWord.list || [];
            const firstList = list[0]?.smartKeyword || [];
            let recWordArr = [];
            firstList.forEach((p, i) => {
                recWordArr.push({
                    jumpUrl: `/search?keyword=${p}`,
                    words: p,
                    class: 'color-red'
                })
            })
            this.recWord.proRecWord = this.arrSplit(recWordArr)
            this.recWord.visible = !!this.recWord.proRecWord.length
            this.getHotAjax()
        },
        handleHotSearch(obj) {
            this.middleEmit.fn('setRecentlySearch', obj.words)
            window.location.href = obj.jumpUrl
        },
        // 数组拆分
        arrSplit(arr, length = 2) {
            let index = 0;
            let newArray = [];
            while(index < arr.length) {
                newArray.push(arr.slice(index, index += length));
            }
            return newArray;
        }
    },
}
