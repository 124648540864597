
import SearchHead from './components/SearchHead'
import SearchRecently from './components/SearchRecently'
import SearchHot from './components/SearchHot'
import SearchAd from './components/SearchAd'
import SearchViewed from './components/SearchViewed'

export default {
    name: 'PreSearch',
    components: {
        SearchHead,
        SearchRecently,
        SearchHot,
        SearchAd,
        SearchViewed
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            middleEmit: {},
            recently: { // 历史搜索
                visible: false,
                list: []
            },
            recWord: { // 商品推荐词
                val: '',
                ids: '',
                list: [],
                hasReferrerProduct: false, // 上一页是否商品详情
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.initialMounted()
        this.getHistorySearch()
        this.getRecSearchWord()
    },
    methods: {
        // 获取历史搜索
        getHistorySearch() {
            this.recently.list = this.$storage.get('search/recently') || []
            this.recently.visible = this.recently.list.length
            // 去重
            let obj = {};
            this.recently.list = this.recently.list.reduce(function(item, next) {
                obj[next.keyword] ? '' : obj[next.keyword] = true && item.push(next);
                return item;
            }, [])
        },
        // 获取商品推荐词
        getRecSearchWord() {
            if(this.recWord.ids && this.recWord.hasReferrerProduct) {
                this.getRecSearchWordAjax()
            } else {
                this.setRecWord()
            }
        },
        getRecSearchWordAjax() {
            this.$api.product.getRecSearchWord({
                spuIds: this.recWord.ids
            }).then(response => {
                const res = response.result || [];
                this.recWord.list = res || []
                const first = this.recWord.list[0] || {};
                const smartKeyword = first.smartKeyword || [];
                this.recWord.val = smartKeyword[0]
                this.setRecWord()
            })
        },
        // 获取商品推荐词后操作
        setRecWord() {
            const ref = this.$refs.SearchHead;
            const refHot = this.$refs.SearchHot;
            ref && ref.setRecWord(this.recWord)
            refHot && refHot.setRecWord(this.recWord)
        },
        // 历史搜索点击
        handleSearch(obj) {
            const ref = this.$refs.SearchHead;
            ref.handleSearch(obj)
        },
        // 设置搜索埋点
        setSearchLog(val) {
            if(!val) return
            this.setRecentlySearch(val)
            this.setSearchLogAjax(val)
        },
        setRecentlySearch(val) {
            this.recently.list.unshift({
                keyword: val
            })
            this.recently.list = this.recently.list.splice(0, 10)
            // 去重
            let obj = {};
            this.recently.list = this.recently.list.reduce(function(item, next) {
                obj[next.keyword] ? '' : obj[next.keyword] = true && item.push(next);
                return item;
            }, [])
            this.$storage.set('search/recently', this.recently.list)
        },
        setSearchLogAjax(val) {
            // this.$api.search.setSearchLog({
            //     searchWords: val
            // }).then(res => {
            // })
            this.$router.push({
                name: 'Search',
                query: {
                    keyword: val
                }
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Search | YFN'),
                keywords: this.$translate('Discover YFN custom jewelry for you including necklaces, rings, bracelets, and charms to show your personality. Customize jewelry in your way'),
                description: this.$translate('Discover YFN custom jewelry for you including necklaces, rings, bracelets, and charms to show your personality. Customize jewelry in your way'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.middleEmit.fn = (fn, options) => {
                this[fn](options)
            }
        },
        initialMounted() {
            const productId = this.$storage.get('product/history') || [];
            const query = this.$route.query;
            this.recWord.hasReferrerProduct = ['product'].includes(query.from)
            this.recWord.ids = (productId.slice(0, 3)).join(',')
        }
    },
}
