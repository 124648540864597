
export default {
    name: 'SearchAd',
    data() {
        return {
            ad: {
                visible: false,
                list: []
            }
        }
    },
    mounted() {
        this.getAdSearchAjax()
    },
    methods: {
        getAdSearchAjax() {
            this.$api.common.getAd({
                places: 10
            }).then(response => {
                const res = response?.result || {};
                this.ad.list = res || []
                this.ad.visible = this.ad.list.length
            })
        },
        jumpLink(obj) {
            // let url = obj.jumpUrl
            // if([7, 'category'].includes(obj.jumpType)) {
            //     url = `${obj.jumpUrl}?id=${obj.categoryId}`
            // }
            window.location.href = obj.jumpUrl
        }
    },
}
