import { render, staticRenderFns } from "./SearchAd.vue?vue&type=template&id=467fd4ff&scoped=true&"
import script from "./SearchAd.vue?vue&type=script&lang=js&"
export * from "./SearchAd.vue?vue&type=script&lang=js&"
import style0 from "./SearchAd.vue?vue&type=style&index=0&id=467fd4ff&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "467fd4ff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnVideo: require('/home/php1/m-nuxt/components/YfnVideo.vue').default})
