
export default {
    name: 'setBasic',
    props: {
        popup: Object,
        userInfo:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            set: {
                list: [],
                policy: []
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        handleSet(obj) {
            this.$router.push({
                name: obj.url
            })
        },
        handlePolicy(obj) {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: obj.id
                }
            })
        },
        setSetting() {
            this.set.list = [{
                name: this.$translate('Change Password'),
                url: 'ChangePwd'
            }, {
                name: this.$translate('Country/Region'),
                text: this.$storage.get('country')?.countryCode,
                url: 'Country'
            }, {
                name: this.$translate('Language'),
                text: this.$storage.get('language').language,
                url: 'LangPage'
            },
            // {
            //     name: this.$translate('Currency'),
            //     text: this.$storage.get('currency').code,
            //     url: 'Currency'
            // }
        ]
        },
        initialSet() {
            this.setSetting()
            this.set.policy = [{
                name: this.$translate('Shipping Policy'),
                id: '1679369506448H4KdPCGQ'
            }, {
                name: this.$translate('Payment Method'),
                id: '1679369593929FHpv8nbx'
            }, {
                name: this.$translate('Terms & Conditions'),
                id: '167936955283278Vtn7Mz'
            }, {
                name: this.$translate('Privacy & Cookie Policy'),
                id: '16793696198020vLSBIcB'
            }]
        }
    },
}
