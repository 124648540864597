import { render, staticRenderFns } from "./SearchWordLibrary.vue?vue&type=template&id=49662e45&scoped=true&"
import script from "./SearchWordLibrary.vue?vue&type=script&lang=js&"
export * from "./SearchWordLibrary.vue?vue&type=script&lang=js&"
import style0 from "./SearchWordLibrary.vue?vue&type=style&index=0&id=49662e45&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49662e45",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnPopup: require('/home/php1/m-nuxt/components/YfnPopup.vue').default})
